var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasUser)?_c('div',{attrs:{"id":"scheduled-orders-table"}},[_c('v-data-table',{attrs:{"show-select":"","single-select":_vm.singleSelect,"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"fixed-header":"","height":_vm.height,"item-key":"id","loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(_vm.showTitle)?_c('v-toolbar-title',[_vm._v("Scheduled Orders")]):_vm._e(),(_vm.showTitle)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('v-text-field',{attrs:{"placeholder":"Search","hide-details":"","clearable":"","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{ref:"scheduledMenu",attrs:{"close-on-content-click":false,"return-value":_vm.scheduledDates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.scheduledDates=$event},"update:return-value":function($event){_vm.scheduledDates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-4",attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,3332817968),model:{value:(_vm.scheduledMenu),callback:function ($$v) {_vm.scheduledMenu=$$v},expression:"scheduledMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.scheduledDates),callback:function ($$v) {_vm.scheduledDates=$$v},expression:"scheduledDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.scheduledMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.setDates}},[_vm._v(" OK ")])],1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,233705595)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.approveSelected}},[_vm._v("Approve")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.snoozeSelected}},[_vm._v("Snooze")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.cancelSelected}},[_vm._v("Cancel")])],1)],1)],1)],1)]},proxy:true},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.fname)+" "+_vm._s(item.customer.lname)),_c('br'),_vm._v(" "+_vm._s(item.customer.shipping_address.street1)+" "+_vm._s(item.customer.shipping_address.street2)+" "),_c('br'),_vm._v(" "+_vm._s(item.customer.shipping_address.city)+", "+_vm._s(item.customer.shipping_address.state.name)+" "+_vm._s(item.customer.shipping_address.zip)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColorMap[item.status]}},[_vm._v(" "+_vm._s(_vm.statusMap[item.status])+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ name: 'scheduled_order_details', params: { id: item.id } })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("View/Edit Scheduled Order")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.snoozeSchedule(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-alarm ")])]}}],null,true)},[_c('span',[_vm._v("Delay Scheduled Order 30 Days")])]),(item.status !== 'CANCELLED')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.cancelSchedule(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',[_vm._v("Cancel Scheduled Order")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.approveSchedule(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',[_vm._v("Approve Scheduled Order")])])]}},(_vm.isEnabled('no-data'))?{key:"no-data",fn:function(){return [_vm._v(" No scheduled orders found. ")]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('confirmation-modal',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }